<template>
  <div id="container">
    <div class="side-left">
      <div class="logo">
        <img src="@/assets/images/logo_powerbiz.png" class="image" alt="logo" />
      </div>
      <div class="left-content">
        <h1>Empowering Your Brand</h1>
        <div class="text-normal">
          We offer an end-to-end solution and services to empower your brand,
          transform your business, and bring your products to global market
          with Omni-Channel Technology Platform
        </div>
        <a class="button button-a-link" href="https://api.whatsapp.com/send?phone=6282131985354&text=Hy%20Powerbiz%20" target="_blank">
          Contact our sales
        </a>
      </div>
    </div>
    <div class="side-right">
      <div class="header">
        <div class="header-content" />
      </div>
      <div class="body">
        <div class="row">
          <div :class="{[$style.card__register]: true, 'w-75': true,}">
            <div v-if="registeredEmail">
              <div class="d-flex flex-column align-items-center" style="gap: 10px 0px;">
                <h4 class="mt-5">
                  {{ 'Success!' }}
                </h4>
                <img
                  src="@/assets/images/icon-success.png"
                  width="100"
                />
                <p class="mt-2 text-center" v-html="`To complete registration please check your email at: <br><b>${registeredEmail}</b>`" />
                <a-button
                  class="mr-2"
                  @click.prevent="toLoginPage()"
                >
                  Back
                </a-button>
              </div>
            </div>
            <div v-else>
              <div class="pbz-font subtitle-md-medium mt-4 mb-4 text-left" style="color: #1a1a1a;">
                Registration
              </div>
              <p class="mb-1">
                Please input your email to receive confirmation
              </p>
              <div v-if="!hasAccount" class="mb-2">
                <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
                  <a-form-model-item
                    prop="email"
                    :class="{
                      [$style.container__form]: true,
                    }"
                    :rules="[{ required: true }]"
                  >
                    <a-input
                      v-model="form.email"
                      type="email"
                      placeholder="email"
                    />
                  </a-form-model-item>
                  <a-button
                    size="large"
                    class="pbz-font body-md-bold text-center w-100 mt-3"
                    :class="{
                      [$style.button__submit]: true,
                    }"
                    :loading="loading"
                    html-type="submit"
                  >
                    Submit
                  </a-button>
                </a-form-model>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
  crossorigin="anonymous"
></script>
<script>
import { selfRegistration } from '@/api/user'

export default {
  components: {
  },
  computed: {
    formError() {
      if (
        !this.passwordError.error_message?.length &&
        !this.confirmPasswordError.error_message?.length
      ) {
        return []
      }

      const data = []
      !!this.passwordError.error_message?.length &&
        data.push(this.passwordError)
      !!this.confirmPasswordError.error_message?.length &&
        data.push(this.confirmPasswordError)

      return data
    },
  },
  data: function () {
    return {
      hasAccount: false,
      loading: false,
      form: {
        email: '',
      },
      passwordError: {
        field: null,
        error_message: [],
      },
      confirmPasswordError: {
        field: null,
        error_message: [],
      },
      idInvited: null,
      registeredEmail: null,
    }
  },
  created () {
  },
  methods: {
    checkFormHaveError(paramater) {
      return this.formError.find((item) => item.field === paramater)
    },
    checkExistingError(errorLog = [], stringParam = '') {
      return errorLog.find((item) => item === stringParam)
    },
    handleHasAccount(param) {
      this.hasAccount = param
    },
    handleSubmit (e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          const payload = {
            email: this.form.email,
            admin: null,
            name: null,
            phone_number: null,
            workspace_role_id: null,
            business_id: null,
            restriction_list: null,
            workspace_role_type: 'SIMPLE',
          }
          await selfRegistration(payload)
          .then(() => {
            this.registeredEmail = this.form.email
            this.loading = false
          })
          .catch((err) => {
            this.$notification.error({
              message: 'Registrasi Gagal',
              description: err.response?.data?.message || err.message,
            })
            this.loading = false
          })
        }
      })
    },
    modalLogout(key) {
      Modal.info({
        title: () => 'Mohon Untuk Logout Terlebih Dahulu!',
        content: () => h('div', {}, [
          h('p', 'Kemudian Klik Kembali Tautan Pada Email Untuk Konfirmasi.'),
        ]),
        onOk() {
          const url = new URL(window.location.href)
          url.pathname = '/auth/login'

          key.logout({
            redirectUri: url.href,
          })
        },
      });
    },
    toLoginPage() {
      window.location.href =  this.$keycloak.createLoginUrl()
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>

<style lang="scss" scoped>
#container {
    display: grid;
    grid-template-columns: 30% 1fr;
    width: 100%;
    justify-content: center;
    height: 100vh;
}

.text-normal {
    font-weight: 400;
    font-size: 16px;
}

.side-left {
    /* background: linear-gradient(180deg, #576af2 -18.97%, #202759 73.39%); */
    background: linear-gradient(153.43deg, #FF8585 0%, #FF0000 83.33%);
    padding: 35px 35px 40px 35px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    color: white;
    height: 100%;
}

.side-right {
    padding: 0px;
}

.left-content {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -80px;
}

.left-content h1 {
    margin-bottom: 40px;
    font-size: 24px;
    color: white;
}

.left-content div.text-normal {
    margin-bottom: 30px;
    letter-spacing: -0.5px;
}

#kc-content,
.login-pf-header {
    position: absolute;
    padding-top: 30px;
}

.image {
    max-width: 232.7px;
    height: auto;
}

.button {
    width: 200px;
    background: #FFFFFF;
    border-radius: 5px;
    height: 48px;
    font-weight: bolder;
    color: #E00000;
    border: 1px solid #E00000;
    font-family: 'Poppins';
    font-style: normal;
}

.button-a-link {
    width: 200px;
    background: #FFFFFF;
    border-radius: 5px;
    height: 48px;
    font-weight: bolder;
    color: #E00000;
    border: 1px solid #E00000;
    font-family: 'Poppins';
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 30px;
}
.button-a-link:hover {
    text-decoration: none;
    color: #E00000 !important;
}
.footer-custom {
    color: #FFFFFF;
    font-size: 12px;
}

.btn-global:hover {
    cursor: pointer;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dropdown {
    background-color: #FFFFFF;
}

.dropdown-btn-custom {
    color: #6c757d;
    background: white;
    border: none;
}

.button-register {
    padding: 6px 10px;
    font-family: 'Poppins';
    font-style: normal;
    color: #E00000;
    background: #FFFFFF;
    border: 0.707246px solid #E00000;
    font-weight: bolder;
    font-size: 14px;

}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: inherit;
    color: rgb(153, 153, 153);
}

.header-content>* {
    margin-right: 10px;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    padding: 40px 30px;
    text-align: left;
    flex-direction: column;
}

.body>div {
    width: 70%;
    display: grid;
    grid-gap: 40px;
}

.input {
    padding: 10px 15px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 10px 15px;
}

.input:active,
.input:focus,
.input:focus-visible,
.input:hover {
    border-color: #78a3ff;
    border-right-width: 1px !important;
}

.content-input {
    display: grid;
    grid-gap: 15px;
}

.content-input>a {
    cursor: pointer;
    color: #006BD1 !important;
    font-weight: 500;
    font-size: 14px;
}

.button-login {
    background: #E00000;
    border-radius: 5px;
    color: white;
    padding: 12px 20px;
    border: none;
}

.separator {
    display: grid;
    flex-direction: row;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.border-line {
    height: 1px;
    background-color: black;
}

/* .content-button-login {
    display: grid;
    grid-gap: 10px;
} */

.content-button-login>button {
    padding: 14px 40px
}

.button-social-media {
    justify-content: center;
    display: flex;
    text-align: left;
    align-items: center;
    flex-direction: row;
}

.button-social-media>img {
    justify-self: center;
    margin-right: 10px;
}

.term {
    font-size: 10px;
    text-align: center;
}

.term>a {
    color: #006BD1 !important;
}

.term>a:hover {
    cursor: pointer;
}

/* Login */
.wrap-login .alert {    
    border: 1px solid transparent;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    border-radius: 5px;
    width: 100%;
    max-width: 450px;
    margin: auto;
}
.content-button-login {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.content-button-login a.btn {
    box-shadow: none !important;
    border: 1px solid #b4b4b4;
    padding: 8px 12px;
    border-radius: 5px;
}
.content-button-login a.btn:hover {
    border: 1px solid #0099d3;
}
.content-button-login .button-login:focus {
    border: 0px !important;
}
input.form-control.input {
    height: 45px;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 14px;
    background: transparent !important;
}
input.form-control.input:-webkit-autofill,
input.form-control.input:-webkit-autofill:hover,
input.form-control.input:-webkit-autofill:focus,
input.form-control.input:-webkit-autofill:active  {
  -webkit-box-shadow: transparent !important;
  background: transparent !important;
  background-color: transparent !important;
}
/* .form-control:focus,
input.form-control.input:focus {
    background: transparent !important;
    box-shadow: none;
    border-color: transparent !important;
} */
input.form-control.input::-webkit-input-placeholder { /* Edge */
    font-style: normal !important;
}

input.form-control.input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-style: normal !important;
}

input.form-control.input::placeholder {
    font-style: normal !important;
}
input.button-login {
    font-size: 14px;
    background: #E00000;
    border-radius: 5px;
    color: white;
    padding: 12px 20px;
    border: 0px;
}
#kc-registration a {
    color: #006BD1 !important;
    font-weight: 500;
    font-size: 14px;
}

/* Register */
.wrap-form-register {
    max-width: 445px;
    margin: auto;
}
.wrap-form-register [id^="input-error-"] {
    color: #E00000;
}


@media only screen and (max-width: 775px) {
    #container {
        grid-template-columns: 1fr;
    }

    .side-left {
        display: none;
    }

    .body {
        padding: 40px 0px;
    }

    .body>div {
        width: 100%;
        display: grid;
        grid-gap: 40px;
    }
}
</style>