var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"container"}},[_vm._m(0),_c('div',{staticClass:"side-right"},[_vm._m(1),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{class:{[_vm.$style.card__register]: true, 'w-75': true,}},[(_vm.registeredEmail)?_c('div',[_c('div',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"10px 0px"}},[_c('h4',{staticClass:"mt-5"},[_vm._v(" "+_vm._s('Success!')+" ")]),_c('img',{attrs:{"src":require("@/assets/images/icon-success.png"),"width":"100"}}),_c('p',{staticClass:"mt-2 text-center",domProps:{"innerHTML":_vm._s(`To complete registration please check your email at: <br><b>${_vm.registeredEmail}</b>`)}}),_c('a-button',{staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.toLoginPage()}}},[_vm._v(" Back ")])],1)]):_c('div',[_c('div',{staticClass:"pbz-font subtitle-md-medium mt-4 mb-4 text-left",staticStyle:{"color":"#1a1a1a"}},[_vm._v(" Registration ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Please input your email to receive confirmation ")]),(!_vm.hasAccount)?_c('div',{staticClass:"mb-2"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
                    [_vm.$style.container__form]: true,
                  },attrs:{"prop":"email","rules":[{ required: true }]}},[_c('a-input',{attrs:{"type":"email","placeholder":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
                    [_vm.$style.button__submit]: true,
                  },attrs:{"size":"large","loading":_vm.loading,"html-type":"submit"}},[_vm._v(" Submit ")])],1)],1):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-left"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/images/logo_powerbiz.png"),"alt":"logo"}})]),_c('div',{staticClass:"left-content"},[_c('h1',[_vm._v("Empowering Your Brand")]),_c('div',{staticClass:"text-normal"},[_vm._v(" We offer an end-to-end solution and services to empower your brand, transform your business, and bring your products to global market with Omni-Channel Technology Platform ")]),_c('a',{staticClass:"button button-a-link",attrs:{"href":"https://api.whatsapp.com/send?phone=6282131985354&text=Hy%20Powerbiz%20","target":"_blank"}},[_vm._v(" Contact our sales ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-content"})])
}]

export { render, staticRenderFns }